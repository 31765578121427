import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { initSentry } from './app/core/sentry/sentry.init';
import { environment } from './environments/environment';

// Init Sentry if not local env
if (!environment.local) initSentry();

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
