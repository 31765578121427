import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { ScanGaugeComponent } from '@codenteam/ui/scan-gauge/scan-gauge.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { ReportHeaderComponent } from '@codenteam/ui/report/report-header/report-header.component';

import { MatTooltip } from '@angular/material/tooltip';
import { Alert, Report, Run, SastError } from '@codenteam/portal/graphql';
import { LetDirective } from '@ngrx/component';
import { map, Observable } from 'rxjs';
import { ApiService } from '../../core/api.service';
import {
  SastVulnTypes,
  sastSeverityOrder,
} from '@codenteam/core/utils/dtos/sast-vuln-types.enum';

@Component({
  selector: 'codenteam-sast-scan-report',
  standalone: true,
  imports: [
    CommonModule,
    MatIcon,
    ScanGaugeComponent,
    MatExpansionModule,
    MatTooltip,
    LetDirective,
    ReportHeaderComponent,
  ],
  templateUrl: './sast-scan-report.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class SastScanReportComponent implements OnInit {
  @Input()
  uuid: string;
  scan$: Observable<SastError[]>;
  run$: Observable<{ uuid: string; name?: string; date?: any; status: string }>;
  elementsBreak: number[];
  account$ = this.apiService.getAccountData();
  userInfo$ = this.apiService.getUserInfo();
  currentDate = new Date();
  examplesCount = 2;
  gaugeData = [
    { label: '1', value: 1, color: '#6EF140' },
    { label: '2', value: 1, color: '#F6BF10' },
    { label: '3', value: 1, color: '#E8872D' },
    { label: '4', value: 1, color: '#E8642D' },
    { label: '5', value: 1, color: '#CF3E3E' },
  ];
  @Input()
  logoPreview$: Observable<string | ArrayBuffer>;

  ngOnInit(): void {
    this.run$ = this.apiService.getRun(this.uuid);

    this.scan$ = this.apiService.getSastResultForRun(this.uuid).pipe(
      map((errors: SastError[]) => {
        return errors.sort((a, b) => {
          return (
            sastSeverityOrder[a.severity as SastVulnTypes] -
            sastSeverityOrder[b.severity as SastVulnTypes]
          );
        });
      })
    );
  }

  constructor(private apiService: ApiService) {}

  calculateRiskPercentage(errors: SastError[]): number {
    const weights = {
      INFO: 0,
      WARNING: 1 / 2,
      ERROR: 1,
    };

    let infoCount = 0;
    let warningCount = 0;
    let errorCount = 0;

    errors.forEach((err) => {
      switch (err.severity) {
        case 'INFO':
          infoCount += err.instances.length;
          break;
        case 'WARNING':
          warningCount += err.instances.length;
          break;
        case 'ERROR':
          errorCount += err.instances.length;
          break;
        default:
          break;
      }
    });

    const weightedSum =
      infoCount * weights.INFO +
      warningCount * weights.WARNING +
      errorCount * weights.ERROR;

    const totalInstances = infoCount + warningCount + errorCount;
    const riskPercentage = ((weightedSum / totalInstances) * 180 - 90) * -1;

    return Math.round(riskPercentage);
  }

  countErrors(errors: SastError[], error: number): number {
    let severity: string;

    /**
     * If combined is true, all risks of same type are counted together.
     */
    const combined = true;

    switch (error) {
      case 0:
        severity = 'INFO';
        break;
      case 2:
        severity = 'WARNING';
        break;
      case 3:
        severity = 'ERROR';
        break;
      case 4: {
        if (combined) {
          return errors.reduce((count, err) => count + 1, 0);
        } else {
          return errors.reduce((count, err) => count + err.instances.length, 0);
        }
      }
    }

    if (combined) {
      return errors
        .filter((err) => err.severity === severity)
        .reduce((count, err) => count + 1, 0);
    } else {
      return errors
        .filter((err) => err.severity === severity)
        .reduce((count, err) => count + err.instances.length, 0);
    }
  }
}
