export enum SastVulnTypes {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  INFO = 'INFO',
}

export const sastSeverityOrder: Record<SastVulnTypes, number> = {
  [SastVulnTypes.ERROR]: 0,
  [SastVulnTypes.WARNING]: 1,
  [SastVulnTypes.INFO]: 2,
};
