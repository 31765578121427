import { Component, OnInit, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { ApiService } from '../core/api.service';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { LetDirective } from '@ngrx/component';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatListItem, MatSelectionList } from '@angular/material/list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { MatTooltip } from '@angular/material/tooltip';
import { Title } from '@angular/platform-browser';
import { DastScan } from '@codenteam/portal/graphql';
import { ReportService } from './report.service';

@Component({
  selector: 'codenteam-report',
  standalone: true,
  imports: [
    CommonModule,
    MatExpansionModule,
    MatIconModule,
    MatDividerModule,
    LetDirective,
    MatCheckbox,
    MatSelectionList,
    MatListItem,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatTooltip,
  ],
  templateUrl: './create-report.component.html',
  styleUrl: './create-report.component.scss',
})
export class CreateReportComponent implements OnInit {
  runs$ = this.apiService.getAllRunsWithShared(null, true);
  scans$ = this.apiService.scansList();
  form = new FormGroup(
    {
      runsControl: new FormControl([]),
      scansControl: new FormControl([]),
    },
    { validators: requireOneControl }
  );
  selectedRunUUID: string;
  selectedDastUUID: string;

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private reportService: ReportService
  ) {
    this.setTitle();
  }
  ngOnInit(): void {
    this.setSelectedRun();
    this.setSelectedDast();
  }
  setSelectedRun() {
    this.reportService.runUUID$.subscribe((runUUID) => {
      if (runUUID) {
        this.selectedRunUUID = runUUID;
        this.runs$.subscribe((runs) => {
          const run = runs.find((run) => run.uuid === this.selectedRunUUID);
          if (run) {
            this.form
              .get('runsControl')
              ?.setValue([run.uuid], { emitEvent: false });
          }
        });
      }
    });
  }

  setSelectedDast() {
    this.reportService.dastUUID$.subscribe((dastUUID: string) => {
      if (dastUUID) {
        this.selectedDastUUID = dastUUID;
        this.scans$.subscribe((scans) => {
          const scan = scans.find(
            (scan) => scan.uuid === this.selectedDastUUID
          );
          if (scan) {
            this.form
              .get('scansControl')
              ?.setValue([scan.uuid], { emitEvent: false });
          }
        });
      }
    });
  }

  setTitle() {
    this.titleService.setTitle(`Codenteam - Report - Creation`);
  }

  onSubmit() {
    if (!this.form.valid) {
      return;
    }
    const queryParams: any = {};
    queryParams.scansArray = JSON.stringify(
      this.form.controls.scansControl.value
    );
    queryParams.runsArray = JSON.stringify(
      this.form.controls.runsControl.value
    );
    queryParams.print = 'true';
    const navigationExtras: NavigationExtras = {
      queryParams,
    };

    const url = this.router.serializeUrl(
      this.router.createUrlTree(['report/report-review'], navigationExtras)
    );
    window.open(url, '_blank');
  }
  checkScans(scans: DastScan[]) {
    const scansDone = scans?.filter((scan) => scan.status === 'Done').length;
    return scansDone === 0 ? true : false;
  }
}
const requireOneControl: ValidatorFn = (
  form: AbstractControl
): ValidationErrors | null => {
  if (
    form.get('runsControl').value.length === 0 &&
    form.get('scansControl').value.length === 0
  ) {
    return { required: 'at least one run or scan is required' };
  }
  return null;
};
