import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ApiTypes, ScanTypes } from '@codenteam/portal/graphql';
import { AuthService } from '../auth/services/auth.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ApiService } from '../core/api.service';
@Component({
  selector: 'codenteam-passive-dast-scan',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
  ],
  templateUrl: './passive-dast-scan.component.html',
})
export class PassiveDastScanComponent {
  constructor(private apiService: ApiService) {}
  scanTypes = ScanTypes;
  form = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$'),
    ]),
    website: new FormControl('', [Validators.required]),
  });
  createPassiveScan() {
    if (this.form.valid) {
      this.apiService
        .passiveDastScanPublic({
          website: this.form.value.website,
          scanType: ScanTypes.Passive,
          apiScanType: null,
          email: this.form.value.email,
        })
        .subscribe();
    }
  }
}
