<div
  *ngrxLet="{
    scan: scan$,
    account: account$,
    user: userInfo$,
    run: run$,
    logoPreview: logoPreview$
  } as context"
  class="report w-full"
>
  <div class="w-full flex-col bg-white text-white">
    <div class="top-center">
      <codenteam-report-header
        [account]="context.account"
        [user]="context.user"
        [logo]="context?.logoPreview"
      ></codenteam-report-header>

      <div
        class="mx-5 mb-4 grid grid-cols-12 items-center justify-between rounded-md bg-slate-200 p-3"
      >
        <div class="col-span-4 flex gap-1">
          <mat-icon class="!h-10 pt-1 !text-2xl !text-slate-900"
            >security</mat-icon
          >
          <div
            class="my-auto w-40 truncate text-xl font-medium leading-4 text-slate-900"
          >
            {{ context.run?.name }}
          </div>
        </div>
        <div class="col-span-4 flex flex-col">
          <div class="flex justify-center align-middle text-slate-900">
            <span class="mr-1 text-gray-600">Type of Scan:</span>
            Static Application Security Testing (SAST)
          </div>
        </div>
        <div class="col-span-4 text-right text-sm text-gray-600">
          <p>
            <span class="mr-1">Date:</span>
            {{ context.run?.date | date : 'dd/MM/yyyy' }}
          </p>
          <p>
            <span class="mr-1">Time:</span>
            {{ context.run?.date | date : 'h:mm a' }}
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="flex flex-col gap-4 bg-white px-5 pb-5">
    <div class="flex gap-5 print:!mb-auto">
      <div
        class="flex h-48 w-1/2 flex-col items-center justify-center gap-4 rounded-md bg-slate-200 p-6"
      >
        <codenteam-scan-gauge
          [percentage]="calculateRiskPercentage(context.scan)"
          [data]="gaugeData"
          [radius]="100"
          class="place-content-center"
        ></codenteam-scan-gauge>
        <div class="text-2xl text-slate-900">Risk</div>
      </div>
      <div
        class="flex h-48 w-1/2 flex-col items-center justify-center rounded-md bg-slate-200 p-6"
      >
        <div class="font-mada mb-3 font-medium">Total Number of Risks</div>
        <div class="mb-2 text-5xl font-bold leading-10">
          {{ countErrors(context.scan, 4) }}
        </div>
        <div class="flex gap-4 rounded-md bg-slate-300 px-6 py-1.5">
          <div class="flex flex-col">
            <mat-icon class="!h-6 !w-[18px] !text-xl !text-blue-600"
              >info</mat-icon
            >
            <div class="pl-1.5 text-sm font-medium">
              {{ countErrors(context.scan, 0) }}
            </div>
          </div>
          <div class="flex flex-col">
            <mat-icon class="!h-6 !w-[18px] !text-xl !text-orange-500"
              >cancel</mat-icon
            >
            <div class="pl-1.5 text-sm font-medium">
              {{ countErrors(context.scan, 2) }}
            </div>
          </div>
          <div class="flex flex-col">
            <mat-icon class="!h-6 !w-[18px] !text-xl !text-red-500"
              >cancel</mat-icon
            >
            <div class="pl-1.5 text-sm font-medium">
              {{ countErrors(context.scan, 3) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-between px-4">
      <div class="text-sm">Points</div>
      <div class="flex gap-[38px]">
        <div class="text-sm">Risk</div>
      </div>
    </div>
    <div>
      <div
        *ngFor="
          let error of context.scan;
          let i = index;
          let first = first;
          let last = last
        "
        [id]="i.toString()"
        [ngClass]="{
        'rounded-t-md': first,
        'rounded-b-md': last,
    
      }"
        class="single-scan-result flex flex-col p-4 odd:!bg-slate-300 even:!bg-slate-200 print:mb-auto print:break-inside-avoid"
      >
        <div class="flex justify-between !text-slate-900">
          <div class="text-lg text-slate-800">
            {{ error.title }} <span class="mr-1">({{ error.severity }})</span>
          </div>
          <div class="flex">
            <mat-icon
              *ngIf="error.severity === 'WARNING' || error.severity === 'ERROR'"
              [ngClass]="
                error.severity === 'WARNING'
                  ? '!text-orange-500'
                  : error.severity === 'ERROR'
                  ? '!text-red-500'
                  : ''
              "
              class="!mt-0.5"
              >cancel</mat-icon
            >
            <mat-icon
              *ngIf="error.severity === 'INFO'"
              class="mt-0.5 !text-blue-600"
              >info</mat-icon
            >
          </div>
        </div>
        <p class="text-base">{{ error.description }}</p>
        <div class="rounded-md border-[1px] border-gray-400">
          <div
            *ngFor="
              let instance of error.instances.slice(0, examplesCount);
              let i = index
            "
            class="flex border-b border-gray-400 px-1.5"
            [ngClass]="{ 'border-b-0': i === error.instances.length - 1 }"
          >
            <div
              class="flex shrink-0 grow-0 basis-1/2 items-center text-wrap py-1"
            >
              <mat-icon
                class="mr-1 text-blue-400"
                svgIcon="file"
              ></mat-icon>
              {{ instance.file }}:{{ instance.lineNo }}
            </div>
            <div class="mx-2 w-[1px] bg-gray-400"></div>
            <div class="w-1/2 py-1">
              <ul
                class="space-y-1"
                *ngIf="instance.lines.length < 10"
              >
                <li
                  *ngFor="let line of instance.lines"
                  class="flex items-center"
                >
                  <mat-icon
                    svgIcon="lines"
                    class="mr-1 text-blue-400"
                  ></mat-icon>
                  <span class="inconsolata break-all text-xs">-{{ line }}</span>
                </li>
              </ul>
              <ul
                class="space-y-1"
                *ngIf="instance.lines.length > 10"
              >
                <li class="flex items-center">
                  <mat-icon
                    svgIcon="lines"
                    class="mr-1 text-blue-400"
                  ></mat-icon>
                  <span class="inconsolata break-all text-xs"
                    >Start: {{ instance.lines[0] }}</span
                  >
                </li>
                <li class="flex items-center">
                  <mat-icon
                    svgIcon="lines"
                    class="mr-1 text-blue-400"
                  ></mat-icon>
                  <span class="inconsolata break-all text-xs"
                    >End: {{ instance.lines[instance.lines.length - 1] }}</span
                  >
                </li>
              </ul>
            </div>
          </div>
          <div
            *ngIf="error.instances.length > examplesCount"
            class="flex border-b border-gray-400 px-1.5"
          >
            + {{ error.instances.length - examplesCount }} more
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
